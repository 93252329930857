import React from "react"
import Layout from "../components/layouts/Layout.js"
import { Link } from "gatsby"

const NotFound = () => (
  <Layout>
    <div className="not-found">
      <h1>404</h1>
      <h2>Document not found</h2>
      <p style={{ textAlign: "center", textDecoration: "underline" }}>
        <Link href="/">Return to homepage</Link>
      </p>
    </div>
  </Layout>
)

export default NotFound
